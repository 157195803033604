/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        window.onscroll = function() {};

        var admin_bar = $('#wpadminbar');

        var nav = $('.app-header');
        var body = $('body');

        $(window).scroll(function () {
            if ($(this).scrollTop() > 62) {
                body.addClass("body-fixed-nav");
                nav.addClass("navbar-fixed-top");
            } else {
                body.removeClass("body-fixed-nav");
                nav.removeClass("navbar-fixed-top");
            }
        });

        $('.close').click(function(){
          console.log('close-clicked');
          $(body).find('.modal').modal('hide');
        });



        $("#menu-menu-primary .menu-item-has-children").each(function() {
          sub_child = $(this).find('.dropdown-menu').children().length;
          if (sub_child > 0) {
            $(this).find('> a').append('<span class="expand ml-2"><i class="icon icon-chevron-down "></i></span>');
          }
        });

        $(".navbar-nav-mobile .menu-item-has-children").each(function() {
          sub_child = $(this).find('.dropdown-menu').children().length;
          if (sub_child > 0) {
            $(this).find('> a').append('<span class="expand ml-2"><i class="icon icon-chevron-right "></i></span>');
          }
        });

        $('#app-mobile-menu .navbar-nav-mobile li .expand').click(function (e) {
            e.preventDefault();

            $(this).toggleClass('active');
            $(this).find('i').toggleClass('icon-chevron-down');
            $(this).parent('a').parent('li').children('.dropdown-menu').slideToggle(100);
        });

        $('.nav-mobile-toggle, .closebtn, .nav-mobile-overlay').click(function () {
            $(".app-wrapper").toggleClass('open');
        });

        // When Class change to GO
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

        $.fn.attrchange = function(callback) {
            if (MutationObserver) {
                var options = {
                    subtree: false,
                    attributes: true
                };

                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(e) {
                        callback.call(e.target, e.attributeName);
                    });
                });

                return this.each(function() {
                    observer.observe(this, options);
                });

            }
        }

        // Triger change
        $('.skill-group-bar').attrchange(function(attrName) {
            if(attrName=='class'){
              $('.single-skill-bar').each(function() {
                $(this).find('.progress-content').animate({
                  width:$(this).attr('data-percentage')
                },2000);
                
                $(this).find('.progress-number-mark').animate(
                  {left:$(this).attr('data-percentage')},
                  {
                   duration: 2000,
                   step: function(now, fx) {
                     var data = Math.round(now);
                     $(this).find('.percent').html(data + '%');
                   }
                });  
              });
            }
        });

        $( function() {
          var $grid = $('.grid').isotope({
            itemSelector: 'article'
          });

          // filter buttons
          $('.filters-button-group').on( 'click', 'button', function() {
            var filterValue = $( this ).attr('data-filter');
            $grid.isotope({ filter: filterValue });
          });
          $('.button-group').each( function( i, buttonGroup ) {
            var $buttonGroup = $( buttonGroup );
            $buttonGroup.on( 'click', 'button', function() {
              $buttonGroup.find('.is-checked').removeClass('is-checked');
              $( this ).addClass('is-checked');
            });
          });

        });

        // debounce so filtering doesn't happen every millisecond
        function debounce( fn, threshold ) {
          var timeout;
          return function debounced() {
            if ( timeout ) {
              clearTimeout( timeout );
            }
            function delayed() {
              fn();
              timeout = null;
            }
            timeout = setTimeout( delayed, threshold || 100 );
          }
        }

        $(window).bind("load", function() {
          $('#all').click();  
        });

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.owl-carousel-testimonials').owlCarousel({
            loop:true,
            margin:30,
            items:2,
            nav:false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                1000:{
                    items:2
                }
            }
        });

        $('.owl-carousel-grid-image').owlCarousel({
            loop:true,
            margin:30,
            items:1,
            nav:false
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
